import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;
  passType: boolean = true;
  passType1: boolean = true;

  constructor(public service: ServiceService, public route: Router,private activated : ActivatedRoute,private http : HttpClient) { 
    this.activated.queryParams.subscribe((res)=>{
      this.token = res.token
    })
  }

  ngOnInit() {
    // this.token = window.location.href.split('=')[1];

    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  verifyEmail: any
  // Reset Password Functionality
  resetPasswordFunc() {

    this.verifyEmail = localStorage.getItem('email')
    var apireq = {
      // 'email': this.verifyEmail,
      'password': this.resetPasswordForm.value.password,
      'confirmPassword': this.resetPasswordForm.value.confirmPassword
    }


    this.service.showSpinner();
    this.postApiFunc('admin/resetPassword', apireq, 1).subscribe(res => {

      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.service.toasterSucc('Password Set Successfully');
        this.route.navigate(['/login'])
      } else {
        // this.service.toasterErr(res.responseMessage)
      }
    }, err => {
      this.service.hideSpinner();
      // this.service.toasterErr(err['error']['responseMessage'])
    })


  }
  postApiFunc(endPointURL: string, data: any, isHeader: number): Observable<any> {
    var httpHeaders;

      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          token: this.token,
        }),
      };

    return this.http.post(this.service.baseUrl + endPointURL, data, httpHeaders);
  }
}

