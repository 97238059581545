import { AddAipPercentComponent } from './aip-management/add-aip-percent/add-aip-percent.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceService } from './service.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './user-management/user-management.component';
//import { HotWalletManagementComponent } from './hot-wallet-management/hot-wallet-management.component';
import { MarketManagementComponent } from './market-management/market-management.component';
import { SettingComponent } from './setting/setting.component';
import { SubAdminManagementComponent } from './sub-admin-management/sub-admin-management.component';
import { WalletManagementComponent } from './wallet-management/wallet-management.component';
import { StaticsContentComponent } from './statics-content/statics-content.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { TermAndServiceComponent } from './term-and-service/term-and-service.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ExchangeManagementComponent } from './exchange-management/exchange-management.component';
import { PaymentAndTransactionComponent } from './payment-and-transaction/payment-and-transaction.component';
import { KycManagementComponent } from './kyc-management/kyc-management.component';
import { TicketManagementComponent } from './ticket-management/ticket-management.component';
import { AddWalletAddressComponent } from './add-wallet-address/add-wallet-address.component';
import { WithdrawlFeeComponent } from './withdrawl-fee/withdrawl-fee.component';
import { DailyLimitComponent } from './daily-limit/daily-limit.component';
import { WithdrawlLimitComponent } from './withdrawl-limit/withdrawl-limit.component';
import { PrevilageSettingComponent } from './previlage-setting/previlage-setting.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BannerSettingComponent } from './banner-setting/banner-setting.component';
import { WebsiteContentSettingComponent } from './website-content-setting/website-content-setting.component';
import { KycActionPageComponent } from './kyc-action-page/kyc-action-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FiatManagementComponent } from './fiat-management/fiat-management.component';
// import { FeeManagementComponent } from './fee-management/fee-management.component';
import { HotcoldwalletManagementComponent } from './hotcoldwallet-management/hotcoldwallet-management.component';
import { ViewtransactionComponent } from './viewtransaction/viewtransaction.component';
import { TradeManagementComponent } from './trade-management/trade-management.component';
import { DisputeManagementComponent } from './dispute-management/dispute-management.component';
import { TradeDetailsComponent } from './trade-details/trade-details.component';
import { DisputeTradeDetailsComponent } from './dispute-trade-details/dispute-trade-details.component';
import { CreateSubadminComponent } from './create-subadmin/create-subadmin.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { EnquireManagementComponent } from './enquire-management/enquire-management.component';
import { WithdrawLimitComponent } from './withdraw-limit/withdraw-limit.component';
import { WalletdetailsComponent } from './walletdetails/walletdetails.component';
import { StaffManagementComponent } from './staff-management/staff-management.component';
import { AddNewStaffComponent } from './add-new-staff/add-new-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ViewStaffComponent } from './view-staff/view-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { ViewAdminComponent } from './view-admin/view-admin.component';
import { UserManagementExchangeComponent } from './user-management-exchange/user-management-exchange.component';
import { ViewUserManagementExchangeComponent } from './view-user-management-exchange/view-user-management-exchange.component';
import { ViewUserManagementExchangeOfFeedbackComponent } from './view-user-management-exchange-of-feedback/view-user-management-exchange-of-feedback.component';
import { ViewUserTradingComponent } from './view-user-trading/view-user-trading.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TakerMakerFeeComponent } from './taker-maker-fee/taker-maker-fee.component';
import { BankManagementComponent } from './bank-management/bank-management.component';
import { LogsManagementComponent } from './logs-management/logs-management.component';
import { DatePipe } from '@angular/common';
import { AdvertisementManagementComponent } from './advertisement-management/advertisement-management.component';
import { AdvertisementDetailsComponent } from './advertisement-details/advertisement-details.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { TicketReplyComponent } from './ticket-reply/ticket-reply.component'
import { CKEditorModule } from 'ngx-ckeditor';
import { MyDatePicker, MyDatePickerModule } from 'mydatepicker';
import { EscrowManagementComponent } from './escrow-management/escrow-management.component';
import { ReplaceUnderScorePipe } from './pipes/replace-under-score.pipe';
import { CustomPipePipe } from './pipes/custom-pipe.pipe';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FeedbackListComponent } from './feedback-management/feedback-list/feedback-list.component';
import { FeedbackViewComponent } from './feedback-management/feedback-view/feedback-view.component';
import { AddFaqComponent } from './faq-management/add-faq/add-faq.component';
import { EditFaqComponent } from './faq-management/edit-faq/edit-faq.component';
import { FaqComponent } from './faq-management/faq/faq.component';
import { ViewFaqComponent } from './faq-management/view-faq/view-faq.component';
import { AddBlogComponent } from './blog-management/add-blog/add-blog.component';
import { EditBlogComponent } from './blog-management/edit-blog/edit-blog.component';
import { ListBlogComponent } from './blog-management/list-blog/list-blog.component';
import { ViewBlogComponent } from './blog-management/view-blog/view-blog.component';
import { StaticContentAddComponent } from './statics-content/static-content-add/static-content-add.component';
import { ListAnnouncementComponent } from './announcement-management/list-announcement/list-announcement.component';
import { EditAnnouncementComponent } from './announcement-management/edit-announcement/edit-announcement.component';
import { AddAnnouncementComponent } from './announcement-management/add-announcement/add-announcement.component';
import { ViewAnnouncementComponent } from './announcement-management/view-announcement/view-announcement.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { ContactusManagementComponent } from './contactus-management/contactus-management.component';
import { CommunityAddComponent } from './community-management/community-add/community-add.component';
import { CommunityEditComponent } from './community-management/community-edit/community-edit.component';
import { CommunityListComponent } from './community-management/community-list/community-list.component';
import { CarrerCategoryListComponent } from './career-management/carrer-category-list/carrer-category-list.component';
import { CarrerCategoryAddComponent } from './career-management/carrer-category-add/carrer-category-add.component';
import { CareerCategoryEditComponent } from './career-management/career-category-edit/career-category-edit.component';
import { CareerCategoryViewComponent } from './career-management/career-category-view/career-category-view.component';
import { ListingUserListComponent } from './listing-managment/listing-user-list/listing-user-list.component';
import { CareerSubcategoryEditComponent } from './career-subcategory-edit/career-subcategory-edit.component';
import { CareerSubcategoryAddComponent } from './career-subcategory-add/career-subcategory-add.component';
import { CareerSubcategoryListComponent } from './career-subcategory-list/career-subcategory-list.component';
import { CareerSubcategoryViewComponent } from './career-subcategory-view/career-subcategory-view.component';
import { ListingUserViewComponent } from './listing-managment/listing-user-view/listing-user-view.component';
import { UserDeatilsNomineeComponent } from './user-deatils-nominee/user-deatils-nominee.component';
import { ManageFeeComponent } from './fee-management/manage-fee/manage-fee.component';
import { MinTradingFeeComponent } from './fee-management/min-trading-fee/min-trading-fee.component';
import { MinWithdrawlAmountComponent } from './fee-management/min-withdrawl-amount/min-withdrawl-amount.component';
import { StandardTradingFeeComponent } from './fee-management/standard-trading-fee/standard-trading-fee.component';
import { UpdateWithdrawlAmountComponent } from './fee-management/update-withdrawl-amount/update-withdrawl-amount.component';
import { UsdPriceComponent } from './fee-management/usd-price/usd-price.component';
import { ContactusViewComponent } from './contactus-management/contactus-view/contactus-view.component';
import { FdListComponent } from './fixed-deposit-management/fd-list/fd-list.component';
import { FdViewComponent } from './fixed-deposit-management/fd-view/fd-view.component';
import { FixedPercentageComponent } from './fixed-deposit-management/fixed-percentage/fixed-percentage.component';
import { ListBannerComponent } from './banner-management/list-banner/list-banner.component';
import { AddBannerComponent } from './banner-management/add-banner/add-banner.component';
import { EditBannerComponent } from './banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './banner-management/view-banner/view-banner.component';
import { ListingCoinComponent } from './listing-coin/listing-coin.component';
import { ManageCoinListComponent } from './manage-coin-list/manage-coin-list.component';
import { AddTeamComponent } from './team-management/add-team/add-team.component';
import { ListTeamComponent } from './team-management/list-team/list-team.component';
import { EditTeamComponent } from './team-management/edit-team/edit-team.component';
import { ViewTeamComponent } from './team-management/view-team/view-team.component';
import { FeeManageComponent } from './fee-management/fee-manage/fee-manage.component';
import { InstantSwapManagementComponent } from './instant-swap-management/instant-swap-management.component';
import { AddAipPlanComponent } from './aip-management/add-aip-plan/add-aip-plan.component';
import { ListAipPlanComponent } from './aip-management/list-aip-plan/list-aip-plan.component';
import { ListAipPercentageComponent } from './aip-management/list-aip-percentage/list-aip-percentage.component';
import { ListAipDetailsComponent } from './aip-management/list-aip-details/list-aip-details.component';
import { EditAipPercentComponent } from './aip-management/edit-aip-percent/edit-aip-percent.component';
import { ViewAipDetailsComponent } from './aip-management/view-aip-details/view-aip-details.component';
import { ViewAipDetailComponent } from './aip-management/view-aip-detail/view-aip-detail.component';
import { EditAipDetailComponent } from './aip-management/edit-aip-detail/edit-aip-detail.component';
import { StakingListComponent } from './staking-management/staking-list/staking-list.component';
import { StakingViewComponent } from './staking-management/staking-view/staking-view.component';
import { TokenManagementComponent } from './token-management/token-management.component';
import { EmergencyStakingComponent } from './fixed-deposit-management/emergency-staking/emergency-staking.component';
import { StakingAddComponent } from './staking-management/staking-add/staking-add.component';
import { StakingEditComponent } from './staking-management/staking-edit/staking-edit.component';
import { ListFeedbackComponent } from './feedback-management/list-feedback/list-feedback.component';
import { AdminBankListComponent } from './feedback-management/admin-bank-list/admin-bank-list.component';
import { OtpVarificationComponent } from './otp-varification/otp-varification.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { SubscribersViewComponent } from './subscribers-view/subscribers-view.component';
import { TutorialListComponent } from './tutorial-list/tutorial-list.component';
import { TutorialViewComponent } from './tutorial-view/tutorial-view.component';
import { TutorialEditComponent } from './tutorial-edit/tutorial-edit.component';
import { TutorialAddComponent } from './tutorial-add/tutorial-add.component';
import { MyProfileContactUpdateComponent } from './my-profile-contact-update/my-profile-contact-update.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ListBuySellTokensComponent } from './Token-Buy/Sell-Management/list-buy-sell-tokens/list-buy-sell-tokens.component';
import { MockHttpCalIInterceptor } from 'src/app/http.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    UserManagementComponent,
    //  HotWalletManagementComponent,
    MarketManagementComponent,
    SettingComponent,
    SubAdminManagementComponent,
    WalletManagementComponent,
    StaticsContentComponent,
    SidemenuComponent,
    AboutUsComponent,
    TermAndServiceComponent,
    PrivacyComponent,
    ExchangeManagementComponent,
    PaymentAndTransactionComponent,
    KycManagementComponent,
    TicketManagementComponent,
    AddWalletAddressComponent,
    WithdrawlFeeComponent,
    DailyLimitComponent,
    WithdrawlLimitComponent,
    PrevilageSettingComponent,
    ResetPasswordComponent,
    MyProfileComponent,
    EditProfileComponent,
    BannerSettingComponent,
    WebsiteContentSettingComponent,
    KycActionPageComponent,
    PageNotFoundComponent,
    FiatManagementComponent,
    // FeeManagementComponent,
    HotcoldwalletManagementComponent,
    ViewtransactionComponent,
    TradeManagementComponent,
    DisputeManagementComponent,
    TradeDetailsComponent,
    DisputeTradeDetailsComponent,
    CreateSubadminComponent,
    UserDetailsComponent,
    DocumentDetailsComponent,
    ProfitLossComponent,
    EnquireManagementComponent,
    WithdrawLimitComponent,
    WalletdetailsComponent,
    StaffManagementComponent,
    AddNewStaffComponent,
    EditStaffComponent,
    ViewStaffComponent,
    FooterComponent,
    AdminManagementComponent,
    AddAdminComponent,
    EditAdminComponent,
    ViewAdminComponent,
    UserManagementExchangeComponent,
    ViewUserManagementExchangeComponent,
    ViewUserManagementExchangeOfFeedbackComponent,
    ViewUserTradingComponent,
    ChangePasswordComponent,
    TakerMakerFeeComponent,
    BankManagementComponent,
    LogsManagementComponent,
    AdvertisementManagementComponent,
    AdvertisementDetailsComponent,
    TicketDetailsComponent,
    TicketReplyComponent,
    EscrowManagementComponent,
    ReplaceUnderScorePipe,
    CustomPipePipe,
    DisclaimerComponent,
    FeedbackListComponent,
    FeedbackViewComponent,
    AddFaqComponent,
    EditFaqComponent,
    FaqComponent,
    ViewFaqComponent,
    AddBlogComponent,
    EditBlogComponent,
    ListBlogComponent,
    ViewBlogComponent,
    StaticContentAddComponent,
    ListAnnouncementComponent,
    EditAnnouncementComponent,
    AddAnnouncementComponent,
    ViewAnnouncementComponent,
    PartnershipComponent,
    ContactusManagementComponent,
    CommunityAddComponent,
    CommunityEditComponent,
    CommunityListComponent,
    CarrerCategoryListComponent,
    CarrerCategoryAddComponent,
    CareerCategoryEditComponent,
    CareerCategoryViewComponent,
    ListingUserListComponent,
    CareerSubcategoryEditComponent,
    CareerSubcategoryAddComponent,
    CareerSubcategoryListComponent,
    CareerSubcategoryViewComponent,
    ListingUserViewComponent,
    UserDeatilsNomineeComponent,

    ManageFeeComponent,
    MinTradingFeeComponent,
    MinWithdrawlAmountComponent,
    StandardTradingFeeComponent,
    UpdateWithdrawlAmountComponent,
    UsdPriceComponent,
    ContactusViewComponent,
    FdListComponent,
    FdViewComponent,
    FixedPercentageComponent,
    ListBannerComponent,
    AddBannerComponent,
    EditBannerComponent,
    ViewBannerComponent,
    ListingCoinComponent,
    ManageCoinListComponent,
    AddTeamComponent,
    ListTeamComponent,
    EditTeamComponent,
    ViewTeamComponent,
    FeeManageComponent,
    InstantSwapManagementComponent,
    AddAipPlanComponent,
    ListAipPlanComponent,
    ListAipPercentageComponent,
    ListAipDetailsComponent,
    AddAipPercentComponent,
    EditAipPercentComponent,
    ViewAipDetailsComponent,
    ViewAipDetailComponent,
    EditAipDetailComponent,
    StakingListComponent,
    StakingViewComponent,
    TokenManagementComponent,
    EmergencyStakingComponent,
    StakingAddComponent,
    StakingEditComponent,
    ListFeedbackComponent,
    AdminBankListComponent,
    OtpVarificationComponent,
    SubscribersComponent,
    SubscribersViewComponent,
    TutorialListComponent,
    TutorialViewComponent,
    TutorialEditComponent,
    TutorialAddComponent,
    MyProfileContactUpdateComponent,
    ListBuySellTokensComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MyDatePickerModule,
    NgxPaginationModule,
    DigitOnlyModule,
    CKEditorModule,
    NgOtpInputModule
  ],
  providers: [ServiceService, DatePipe, BnNgIdleService, {
    provide: HTTP_INTERCEPTORS,
    useClass: MockHttpCalIInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
